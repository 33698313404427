import React from "react";

import Layout from "../../../components/Layout/Layout";

const Article26032020 = () => {
  return (
    <Layout>
      <div className="article">
        <div className="article__ribbon">
          <div className="grid-container">
            <h1 className="title">
              S&T AG: 2019 - NINTH RECORD YEAR IN SUCCESSION
            </h1>
            <p className="bullet">
              {" "}
              Sales growth of 13% to EUR 1,122.9 million (PY: EUR 990.9 million)
            </p>
            <p className="bullet">
              {" "}
              Over proportional EBITDA increase of 23% to EUR 111.7 million (PY:
              EUR 90.5 million)
            </p>
            <p className="bullet">
              Significant improvement of operating cash flow to EUR 83.4 million
              (PY: EUR 35.5 million)
            </p>
            <p className="bullet">
              {" "}
              Record cash position of EUR 312.3 million (PY: EUR 171.8 million)
            </p>
            <p className="bullet">
              {" "}
              Dividend policy continued: further increase up to 19 cents (PY: 16
              cents) planned for 2019
            </p>
            <p className="bullet">
              {" "}
              Re-evaluation of 2020 guidance due to the spread of the SARS-CoV-2
              virus
            </p>
            <p>
              The technology group S&T AG presents record results for the ninth
              year in a row. The company recorded a 13% increase in sales over
              the previous year to EUR 1,122.9 million (previous year: EUR 990.9
              million), thus exceeding the billion-euro mark for the first time.
              The operating result before depreciation and amortization (EBITDA)
              rose by 23% to EUR 111.7 million (PY: EUR 90.5 million). As a
              result, the EBITDA target for 2023 was further increased to EUR
              220 million in January 2020. Earnings per share increased to 74
              cents in 2019 compared to 70 cents in 2018. The "PEC" ("Profit,
              Efficiency, Cash") programme also made significant progress in
              optimizing working capital. Operating cash flow improved
              significantly to a record EUR 83.4 million (PY: EUR 35.5 million).
              As a result, cash and cash equivalents also increased
              significantly to EUR 312.3 million (PY: EUR 171.8 million) at
              year-end. The order backlog and project pipeline also reached new
              record levels with increases of over 30%. The excellent financial
              situation also allows S&T AG to continue its annually increasing
              dividend policy despite the current “corona crisis”: The
              Management Board of S&T AG will therefore propose a dividend of 19
              cents (PY: 16 cents) for the past financial year to the Annual
              General Meeting.
            </p>
            <p>
              However, these excellent figures are dwarfed by the global spread
              of the SARS-CoV-2 virus and the uncertainty that accompanies it.
              It is not yet clear how the influence of government measures to
              contain the pandemic and its massive impact on the economy will be
              assessed. Accordingly, significant distortions must be assumed for
              the further course of events in 2020. A significant impact on the
              global economy is predicted, which S&T AG will not be able to
              avoid in the short term. Despite the impact on the supply chain,
              S&T was able to make a good start to the first quarter according
              to plan, with continued strong incoming orders. Nevertheless, we
              will have to reconsider our previous annual target and adapt it as
              soon as the effects of the pandemic become more transparent.
            </p>
            <p>
              S&T also sees numerous opportunities in the current "corona
              crisis", be it through increased demand in the medical technology
              sector, home offices or lower company valuations, which support
              the S&T Group's M&A strategy. S&T is crisis-proof and, with over
              EUR 300 million in cash on hand, is well prepared for acquisition
              opportunities as they arise. Therefore, the ambitious medium-term
              target for 2023 of EUR 2 billion in revenues and an EBITDA of EUR
              220 million remains unchanged.
            </p>
            <p>
              {" "}
              "We have closed the 2019 financial year with excellent figures and
              are very confident of continuing our success, even under the
              changed conditions. With our technical solutions and products, for
              example in the medical technology sector, and the record cash
              position of EUR 312 million, we are well positioned and will
              emerge from the crisis stronger than before." states Hannes
              Niederhauser, CEO of S&T AG.{" "}
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Article26032020;
